import axios, { AxiosResponse } from 'axios';
import { Registration } from '../types';

const REGISTRATION = `https://ay2czymch6bd3u4erq4b6b4wqa0cudqo.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}

class RegistrationApi {

  putUser = async (registration:Registration): Promise<AxiosResponse> => {
      try {
        return axios.put(`${REGISTRATION}`,registration, { headers });
      } catch (e) {
        throw e;
      }
  }

}
export default RegistrationApi;