import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Assessment from './modules/assessment';
import Contact from './modules/home/contact';
import Home from './modules/home/home';
import Login from './modules/home/login';
import Position from './modules/positions';
import Registration from './modules/assessment/registration';
import Results from './modules/assessment/results';
import './app.css';

function App() {

  const gaTrackingID =
  process.env.NODE_ENV === 'production' ? 'UA-79559806-1' : 'UA-79559806-1';

  ReactGA.initialize(gaTrackingID, {
    debug: false,
    titleCase: false
  });

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="439335160864-hn17pvh7fog1j20pa17sc2tu0ncngjc0.apps.googleusercontent.com">
      <div className="appBody">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/assessments" element={<Assessment />} />
            <Route path=":assessment" element={<Registration />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/positions" element={<Position />} />
            <Route path="/results" element={<Results />} />
          </Routes>          
        </Router>
      </div>
      </GoogleOAuthProvider>
    </div>
  );

}

export default App;
