import React, { useState, useEffect, useCallback, MouseEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import Header from './header';
import SectionFC from './section/index';
import AssessmentContext from './context';
import { Assessment, Question, QuestionAnalytic, Results} from './types';
import AssessmentApi from './api';
import './index.css';

const AssessmentFC: React.FC = () => {

  const [sectionIndex, setSectionIndex] = useState<number>(0);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [assessment, setAssessment] = useState<Assessment>({internalName: "",name:"", sections:[]});
  const [isSending ] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  async function checkLocalStorage(navigate: any) {
    if (
      !localStorage.getItem('assessmentName') ||
      localStorage.getItem('status') !== 'pending'
    ) {
      navigate('/');
    }
  }

  async function getAssessment() {
    await checkLocalStorage(navigate);
    const assessmentName = localStorage.getItem('assessmentName') || '';
    new AssessmentApi().getAssessment(assessmentName)
    .then(response => response.data)
    .then(assessment => {
      setAssessment(assessment);
    })
    .catch(error => {
      throw Error('Unable to get assessment ' + error);
    });
  }

  useEffect(() => {
    try {
      ReactGA.pageview('/assessments');
      getAssessment();
    }
    catch (e) {
      setError('Error');
    }
  }, []);

  const goToSection = (e:MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>,requestedSection: number, requestedQuestion: number,q: Question) => {
    setSectionIndex(requestedSection);
    setQuestionIndex(requestedQuestion);
    submitQuestionAnalytic(q);
  };

  const handleNextQuestion = (e:MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>, nextQuestionIndex:any, q:Question) => {
    if (e.type = "click") {
      e.preventDefault();
      e.stopPropagation();
    }
    setQuestionIndex(nextQuestionIndex);
    submitQuestionAnalytic(q);
  };

  const submitQuestionAnalytic = async (q:Question) => {
    try {
      let timeToAnswer = 1;
      if (q.startTime == null) {
        q.startTime = q.endTime - 1000;
      };
      timeToAnswer=q.endTime-q.startTime;
      let numberOfClicks = q.numberOfClicks;
      let correct;
      if (q.choosenQuestionOption.score > 0) {
        correct=1;
      } 
      else {
        correct=0;
      }
      const qa: QuestionAnalytic = {
        assessmentId: assessment.id,
        question: q,
        timeToAnswer: timeToAnswer,
        numberOfClicks: numberOfClicks,
        correct: correct
      };
      const response = await new AssessmentApi().putQuestionAnalytic(qa);
      if ((await response).status == 200) {
        ReactGA.event({
          category: 'Question',
          action: 'Successful Question Analytic'
        });
      }
    }
    catch (err) {
      setError('Error');
      ReactGA.event({
        category: 'Question',
        action: 'Failed Question Analytic'
      });
    }
  };

  const submitAssessment = useCallback(async (e:MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>, assessment:Assessment,q:Question) => {
    try {
      let results:Results = {
        assessmentName: assessment.name,
        assessmentInternalName: assessment.name,
        totalScore: 0,
        sections: assessment.sections
      }
      submitQuestionAnalytic(q);
      const response = await new AssessmentApi().putAssessmentResults(results);
      if ((await response).status == 200) {
        ReactGA.event({
          category: 'Assessment',
          action: 'Successful Submit'
        });
        localStorage.setItem('status', 'final');
        navigate(`/results`,{ state: results });
      }
    }
    catch (err) {
      setError('Error');
      ReactGA.event({
        category: 'Assessment',
        action: 'Failed Submit'
      });
    }
  }, [isSending]);

  const goForward = () => {
    const currentQuestion = assessment.sections[sectionIndex].questions[questionIndex];
    assessment.sections[sectionIndex].questions[questionIndex].startTime = Date.now();
    const nextSectionIndex = sectionIndex + 1;
    const isLastSection = nextSectionIndex > assessment.sections.length - 1;

    const nextQuestionIndex = questionIndex + 1;
    const isLastQuestionInSection =
      nextQuestionIndex > assessment.sections[sectionIndex].questions.length - 1;

    if (isLastSection && isLastQuestionInSection) {
      return (
        <>
        {error && (
          <div className="errors">
            <span>{error}</span>
          </div>
        )}
        <button
          type="submit"
          className="navButton"
          onClick={(e) => submitAssessment(e,assessment, currentQuestion)}
        >{`Submit`}</button>
        </>
      );
    }

    if (isLastQuestionInSection) {
      return (
        <button
          type="submit"
          className="navButton"
          onClick={(e) => goToSection(e,nextSectionIndex, 0, currentQuestion)}
        >{`Next`}</button>
      );
    }

    return (
      <button
        type="submit"
        className="navButton"
        onClick={(e) => handleNextQuestion(e,nextQuestionIndex, currentQuestion)}
      >{`Next`}</button>
    );
  };

  return (
    <div className="assessmentWrapper">
      <Header />
      {assessment.sections.length > 0 ? (
        <AssessmentContext.Provider
          value={{ sectionIndex, questionIndex, assessment, setAssessment }}
        >
          <div className="assessmentContainer">
            <div className="assessmentBody">
              <SectionFC
                goForward={goForward}
                goBack={() => true}
                assessmentName={assessment.name}
              />
            </div>
          </div>
        </AssessmentContext.Provider>
      ) : (
          <p></p>
        )}
    </div>
  );
};

export default AssessmentFC;
