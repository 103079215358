import axios from 'axios';

import { Position } from './position';


import { REACT_APP_API_ROOT } from '../../constants';

const POSITIONS_GET = `https://wyuc5bdbq2xmgc7uqme2zxjpde0ekrbb.lambda-url.us-east-1.on.aws`;
const POSITIONS = `${REACT_APP_API_ROOT}`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}
class PositionsApi {


  public getPositions = async (): Promise<any> => {
    try {
      try {
        //headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        return axios.get(`${POSITIONS_GET}`, { headers });
      } catch (e) {
        throw e;
      }
    } catch (e) {
      throw e;
    }
  }

    positions = {
      get: async (): Promise<any> => {
          try {
            //headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            return axios.get(`${POSITIONS_GET}`, { headers });
          } catch (e) {
            throw e;
          }
      },
      getOne: async (position:Position): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.get(`${POSITIONS}/${position.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
      put: async (position:Position): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.put(`${POSITIONS}`,position, { headers });
        } catch (e) {
          throw e;
        }
      },
      patch: async (position:Position): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.patch(`${POSITIONS}/${position.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
      delete: async (position:Position): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.delete(`${POSITIONS}/${position.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
  };


}
export default PositionsApi;