import React, { useState, useEffect } from 'react';
import './index.css';

const RadioInput: React.FC<{
  options: { question_option_order: string; text: string; score: number }[];
  questionId: string;
  setChoosenQuestionOption: Function;
  answer: string;
}> = ({ options, questionId, setChoosenQuestionOption, answer }) => {
  const [isChecked, setChecked] = useState(answer);

  useEffect(() => {
    setChecked(answer);
  }, [answer]);

  const checkOption = (selected: string) => {
    setChecked(selected);
    setChoosenQuestionOption(JSON.parse(selected));
  };
  
  return (
    <div className="radioWrapper">
      {options.map((option, i) => (
        <div
          key={`option-${i}`}
          className="radioOptionWrapper"
          onClick={() => checkOption(JSON.stringify(option))}
        >
          <input
            type="radio"
            name={questionId}
            onChange={(evt) => checkOption(evt.target.value)}
            value={JSON.stringify(option)}
            id={option.question_option_order}
            checked={isChecked === JSON.stringify(option)}
          />
          <label><pre>{option.text}</pre></label>
        </div>
      ))}
    </div>
  );
};

const Inputs = {
  radio: RadioInput
};

export default Inputs;
