import React from 'react';
import { AssessmentState } from './types';

const defaultContext = {
    sectionIndex: 0,
    questionIndex: 0,
    assessment: {internalName: "", name:"", sections: []},
    setAssessment: () => true
}

const AssessmentContext = React.createContext<AssessmentState>(defaultContext);

export default AssessmentContext