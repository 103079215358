import axios, { AxiosResponse } from 'axios';
import { Contact } from './types';

const CONTACT = `https://zc2ynw5fgzbmqejvzyhknbeomi0gbqic.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}

class ContactsApi {

  putContact = async (contact:Contact): Promise<AxiosResponse> => {
      try {
        return axios.put(`${CONTACT}`,contact, { headers });
      } catch (e) {
        throw e;
      }
  }

}
export default ContactsApi;