import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { googleLogout } from '@react-oauth/google';
import logo from '../../../images/logo.png';

import { Question, QuestionOption, Results } from '../types';
import './index.css';

const emptyResults = {
  assessmentInternalName: '',
  assessmentName: '',
  totalScore: 0,
  sections: []
};

function forceCast<T>(input: any): T {
    // @ts-ignore
    return input;
}

const calculateTotalScore = (results: Results) => {
  let totalScore = 0;
  results.sections.map((section) => {
    section.questions.map((question) => {
      if (question.choosenQuestionOption) {
        totalScore += question.choosenQuestionOption.score;
      }
    });
  });
  results.totalScore = totalScore;
}

const calcQuestionClassName = (q: Question) => {
  const success = 1;
  const failed = 0;
  if (q.choosenQuestionOption == null) {
    return 'question-notanswered';
  }
  if (q.choosenQuestionOption.score == success) {
    return 'question-success';
  } 
  if (q.choosenQuestionOption.score == failed) {
    return 'question-failed';
  } 
  return '';
}

const calcQuestionOptionText = (q: Question, qo: QuestionOption) => {
  const success = 1;
  if (qo.score == success) {
    return qo.text + ' (correct)';
  } 
  return qo.text;
}

const calcQuestionOptionStyle = (q: Question, qo: QuestionOption) => {
  const success = 1;
  const failed = 0;
  const fontSize = 12;
  if (q.choosenQuestionOption == null) {
    return {
      'font-size': fontSize,
      color: '',
    };
  }
  if (q.choosenQuestionOption.question_option_order == qo.question_option_order && q.choosenQuestionOption.score == success) {
    return {
      'font-size': fontSize,
      color: 'green',
    };
  }
  if (q.choosenQuestionOption.question_option_order == qo.question_option_order && q.choosenQuestionOption.score == failed) {
    return {
      'font-size': fontSize,
      color: 'red',
    };
  }
  return {
    'font-size': fontSize,
    color: '',
  };
}


const ResultsPage = () => {

  const [results, setResults] = useState<Results>(emptyResults);
  const location = useLocation();

  useEffect(() => {
    let results = forceCast<Results>(location.state)
    calculateTotalScore(results);
    setResults(results);
    googleLogout();
    ReactGA.pageview('/results');
  }, [results]);

  const setQuestionTextStyle = () => {
    return {
      'font-size': 20
    };
  }

  const setQuestionCodeStyle = (code:string) => {
    if (code == "") {
      return {
      };
    }
    return {
      'background-color': '#fcfdfe',
      'border': '1px solid #c7c7c7',
      'font-size': 12
    };
  }

  const calcQuestionOptionChecked = (q:any,qo:any) => {
    let checked = false;
    if (q.choosenQuestionOption == null) {
      return checked;
    }
    if (q.choosenQuestionOption.question_option_order == qo.question_option_order) {
      checked = true;
      return checked;
    } 
    return checked;
  }


  return (
    <div className="results-wrapper">
      <div className="header">
        <div>
        <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
          <img src={logo} />
        </a>
        </div>
      </div>
      <div className="results-body">
        <div className="results-section">
          <div>
            <div className='results-h1'>{results.assessmentName} assessment results</div>
            <div className='results-h1'>Score:  {results.totalScore}</div>
            <hr />
          </div>
          <div className="results-h1">
            {results.sections.map((s, si) => (
            <div key={si}>
              <div className="results-h1">{s.name}</div>
              <br/>
              {s.questions.map((q, qi) => (
                <div key={qi}>
                  <div>{q.text}</div>
                  <div style={setQuestionCodeStyle(q.code)}><pre>{q.code}</pre></div>
                  <br/>
                  {q.options.map((o, oi) => (
                    <div key={oi}>
                    <input type="radio" checked={calcQuestionOptionChecked(q,o)}/>
                    <label style={calcQuestionOptionStyle(q,o)}>{calcQuestionOptionText(q,o)}</label>
                    </div>
                  ))}
                  <br/>
                </div>
              ))}
              <hr />
            </div>
            ))}            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
