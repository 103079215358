import React, { useContext } from 'react';
import AssessmentContext from '../context';
import { Question, QuestionOption } from '../types';
import Inputs from '../inputs';
import './index.css';

const QuestionFC: React.FC<Question> = ({
  id,
  type,
  text,
  code,
  asset,
  options,
  choosenQuestionOption: questionOption
}) => {

  const { sectionIndex, questionIndex, assessment, setAssessment } = useContext(
    AssessmentContext
  );

  const setChoosenQuestionOption = (questionOption: QuestionOption) => {
    if (!assessment.sections[sectionIndex].questions[questionIndex].numberOfClicks) {
      assessment.sections[sectionIndex].questions[questionIndex].numberOfClicks = 1;
    } else {
      assessment.sections[sectionIndex].questions[questionIndex].numberOfClicks += 1;
    }
    assessment.sections[sectionIndex].questions[questionIndex].choosenQuestionOption = questionOption;
    assessment.sections[sectionIndex].questions[questionIndex].endTime = Date.now();
    setAssessment(assessment);
  };

  const inputComponent = (input: typeof Inputs, key: string) => {
    return input[key];
  };

  return (
    <div className="questionWrapper">
      <div className="questionText">
      <pre><React.Fragment>{text}</React.Fragment></pre>
      </div>
      {code != "" && (
      <div className="questionCode">
        <pre><React.Fragment>{code}</React.Fragment></pre>
      </div>
      )}
      {asset != "" && (
      <div className="questionAsset">
        <img src={asset} />
      </div>
      )}
      {!!inputComponent && inputComponent(Inputs, type)({ options, id, questionOption, setChoosenQuestionOption })}
    </div>
  );
};

export default QuestionFC;