import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import logo from '../../../images/logo.png';
import ContactApi from './api';
import { Contact } from './types';
import { Input } from './types';
import './index.css';

const formTemplate = [
  {
    label: 'First Name',
    name: 'firstName',
    isRequired: false,
    error: '',
    value: ''
  },
  {
    label: 'Last Name',
    name: 'lastName',
    isRequired: false,
    error: '',
    value: ''
  },
  {
    label: 'Email',
    name: 'email',
    isRequired: true,
    error: '',
    value: ''
  },
  {
    label: 'Message',
    name: 'message',
    isRequired: true,
    error: '',
    value: ''
  }
];

async function putForm(contact: Contact) {
  return await new ContactApi().putContact(contact);
}

function moreThanOneHundredChars(text: string) {
  return text.length > 100;
}

function moreThanOneChar(text: string) {
  return text.length === 0;
}

const ContactPage = () => {

  const [error, setError] = useState('');
  const [isSubmitting] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Input[]>(
    formTemplate
  );

  const updateForm = (value: string, index: number) => {
    const freshForm = [...formData];
    freshForm[index].value = value;
    setFormData(freshForm);
  };

  async function validateForm(form: Input[]) {
    const freshForm = [...formData];
    await Promise.all(
      form.map(async (singleInput, i) => {
        if (moreThanOneHundredChars(singleInput.value)) {
          freshForm[i].error = 'Must be less than 100 characters.';
          throw Error('Must be less than 100 characters.');
        }
        if (singleInput.isRequired && moreThanOneChar(singleInput.value)) {
          freshForm[i].error = 'Required field.';
          throw Error('Required Field');
        }
        return true;
      })
    );

    return form.reduce(
      (responseObj: any, singleInput: Input) => {
        const name = singleInput.name;
        return Object.assign(responseObj, { [name]: singleInput.value });
      },
      {}
    );
  }

  const submit = async (contact:Contact) => {
    try {
      const response = await putForm({
        ...contact
      });

      if (response.status == 200) {
        navigate(`/`);
        ReactGA.event({
          category: 'Contact',
          action: 'Successful Contact Stored'
        });
      }
    } catch (err) {
      console.error(err);
      setError('Error');
      ReactGA.event({
        category: 'Contact',
        action: 'Failed Contact Storage'
      });
    }

  }

  const validateThenSubmit = useCallback(async () => {

    const form = await validateForm(formData);
    submit(form);

  }, [isSubmitting]);

  return (
    <div className="wrapper">
      <div className="header">
        <div>
          <img src={logo} />
        </div>
      </div>
      <div className="body">
        <div className="contentWrapper">
          <div className="content">
            <div className="form">
              <div className='title'>Send us a message</div>
              <br />
              <div className="inputs">
                {formTemplate.map((singleInput, i) => (
                  <div
                    key={'singleInput-' + i}
                    className={`singleInput 
                    ${singleInput.error.length ? 'errored' : ''}
                    ${singleInput.value.length ? 'active' : ''}
                    `}
                  >
                    <label>{singleInput.label}</label>
                    <input
                      id={singleInput.name}
                      type="text"
                      onChange={(evt) => updateForm(evt.target.value, i)}
                      value={singleInput.value}
                    />
                    {singleInput.error.length > 0 && (
                      <div className="inputError">
                        <span>
                          {singleInput.error}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {error && (
                <div className="errors">
                  <span>{error}</span>
                </div>
              )}
              <div className="sendButton">
                <button className="submit" onClick={() => validateThenSubmit()}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <br />
          <br />
          <div className="footer">
          <span>© techassessments.io 2024. All rights reserved. </span>
          <br />
          </div>
          <br />
          <a href={process.env.PUBLIC_URL + '/privacy-policy.html'}
            className="footerLeftLink"
            target="_blank">
            <span>Privacy</span>
          </a>

          <a href={process.env.PUBLIC_URL + '/terms-of-use.html'}
          className="footerRightLink"
          target="_blank">
            <span>Terms</span>
          </a>

        </div>
      </div>
    </div>
  );
};

export default ContactPage;
