import React, { useContext } from "react";
import './index.css';
import AssessmentContext from '../context'
import QuestionFC from '../question'
import ProgressBar from "../progress";

const AssessmentNav: React.FC<{ backStep: Function, forwardStep: Function }> = ({ backStep, forwardStep }) => (
    <div className="sectionNav">
        <div className="sectionNavBody">
            {forwardStep()}
        </div>
    </div>
)

const SectionFC: React.FC<{ goForward: Function, goBack: Function, assessmentName: string}> = ({ goForward, goBack, assessmentName}) => {
    const { sectionIndex, questionIndex, assessment } = useContext(AssessmentContext)
    const { name, questions } = assessment.sections[sectionIndex];

    return (
        <div className="sectionWrapper">
            <div className="sectionUpperContainer">
                <div className="sectionHeaderWrapper">
                    <div className="sectionHeaderText">
                        <div>
                            <div className="assessmentName">{assessmentName} assessment</div>
                            <div className="sectionName">{name}</div>
                        </div>
                    </div>
                    <div className="sectionHeaderProgress hideDesktop">
                        <ProgressBar sectionId={sectionIndex} />
                    </div>
                </div>
                <div className="sectionBody">
                    <QuestionFC key={`question-${questionIndex}`} {...questions[questionIndex]} />
                </div>
            </div>
            <AssessmentNav forwardStep={goForward} backStep={goBack} />
        </div>
    )
}

export default SectionFC