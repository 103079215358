import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { OpenInNew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png';
import './home.css';

const useStyles = makeStyles({
    media: {
        height: '18em',
    },
    grid: {
        height: "100%"
    }
});

const translate = (key: string) => {
    if (key === 'home.platform.prompt-storage') {
        return 'Large Language Model (LLM) Prompt Storage in the Cloud';
    }
    if (key === 'home.platform.prompt-storage-details') {
        return 'Configure and store system and use cases specific prompts for ease of use and storage in the cloud.';
    }
    if (key === 'home.signup') {
        return 'Register';
    }
    if (key === 'home.contact') {
        return 'Contact';
    }
    if (key === 'home.login') {
        return 'Login';
    }
    if (key === 'home.platform.intro') {
        return 'Assess your direct reports or candidates with low-cost effective surveys to determine their technical understanding.';
    }
    if (key === 'home.platform.intro2') {
        return 'Automate hiring: use AI to generate job descriptions and dynamic assessments that capture and test for knowledge and not just recall.';
    }
    if (key === 'home.platform.intro3') {
        return 'Continuous growth: from hiring to promotions to retention we’ve got you covered.';
    }
    if (key === 'home.platform.feature1') {
        return 'Automated Job Descriptions';
    }
    if (key === 'home.platform.feature1.description') {
        return 'Writing job descriptions in a document is now a thing of the past. The information is locked up and divorced from day to day operations. Make your job descriptions a living document that is tied to learning and development.';
    }
    if (key === 'home.platform.feature1') {
        return 'Continuity Beyond Recruiting';
    }
    if (key === 'home.platform.feature1.description') {
        return 'New employees typically have to take an assessment to qualify for a position. unfortunately, thats where the learning measurement stops.  Employers just expect employees to grow with the company and continue to grow within their position. Now there is continuity from hiring assessment , project work and career planning.';
    }
    if (key === 'home.platform.feature2') {
        return 'Helpful Assessments';
    }
    if (key === 'home.platform.feature2.description') {
        return 'Help your team members grow professionally with assessments that are tied to position leveling and skills that matter.';
    }
    if (key === 'home.platform.feature3') {
        return 'Align Company Values';
    }
    if (key === 'home.platform.feature3.description') {
        return 'Align company values and behaviors to compentency assessments for both skills and traits.';
    }
    if (key === 'home.platform.feature4') {
        return 'Continuity Beyond Recruiting';
    }
    if (key === 'home.platform.feature4.description') {
        return 'New employees typically have to take an assessment to qualify for a position. unfortunately, thats where the learning measurement stops. Employers just expect employees to grow with the company and continue to grow within their position. Now there is continuity from hiring assessment , project work and career planning.';
    }
    if (key === 'home.consulting.intro1') {
        return 'We work with companies of all sizes and shapes. From biotech to fintech and everything in between.';
    }
    if (key === 'home.consulting.intro2') {
        return 'We provide consulting for both technical and regulatory decision making.';
    }
    if (key === 'home.consulting.intro3') {
        return 'We offer custom software product development.';
    }
    if (key === 'home.consulting.intro4') {
        return 'Use the Contact button to reach us.';
    }
    if (key === 'home.platform') {
        return 'Platform';
    }
    if (key === 'home.welcme.intro6') {
        return 'Platform features';
    }
    if (key === 'home.consulting') {
        return 'Consulting';
    }
    if (key == 'home.product') {
        return 'product';
    }
    if (key == 'home.fullstack') {
        return 'fullstack';
    }
    if (key == 'home.card1') {
        return 'dataengineering';
    }
    if (key == 'home.set') {
        return 'set';
    }
    if (key == 'home.privacy') {
        return 'Privacy Policy';
    }
    if (key == 'home.terms') {
        return 'Terms of Use';
    }
    if (key == 'home.copyright') {
        return '© techassessment.io 2024. All rights reserved. ';
    }
    return key;
};

const Home = () => {
    const classes = useStyles();
    return (
        <Container>
            <div className="home-header">
                <Button href="/signup" disabled>
                    {translate('home.signup')}
                </Button>
                <Button href="/contact">
                    {translate('home.contact')}
                </Button>
                <Button href="/login">
                    {translate('home.login')}
                </Button>
            </div>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid direction='row' xs={12} style={{ textAlign: "center" }}>
                    <Card elevation={0}>
                        <CardContent>
                        <Paper elevation={0}>
                                <Typography>{}</Typography>
                        </Paper>
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardContent>
                            <div>
                                <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
                                    <img aria-hidden="true" src={logo} />
                                </a>
                            </div>
                            <div>
                            <Typography variant="h2" component="h3">
                                {translate('home.platform')}
                            </Typography>
                            </div>
                            <Typography variant="h5" component="h3">
                                {translate('home.platform.intro')}
                            </Typography>
                            <div><br></br></div>
                            <Typography variant="h5" component="h3">
                                {translate('home.platform.intro2')}
                            </Typography>
                            <div><br></br></div>
                            <Typography variant="h5" component="h3">
                                {translate('home.platform.intro3')}
                            </Typography>
                            <div><br></br></div>
                            <div><br></br></div>
                            <Card elevation={3} style={{textAlign: "center"}}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                {translate('home.platform.feature1')}
                                </Typography>
                                <Typography color="textSecondary">
                                {translate('home.platform.feature1.description')}
                                </Typography>
                                <div><br></br></div>
                                <Typography variant="h5" component="h2">
                                {translate('home.platform.feature2')}
                                </Typography>
                                <Typography color="textSecondary">
                                {translate('home.platform.feature2.description')}
                                </Typography>
                                <div><br></br></div>
                                <Typography variant="h5" component="h2">
                                {translate('home.platform.feature3')}
                                </Typography>
                                <Typography color="textSecondary">
                                {translate('home.platform.feature3.description')}
                                </Typography>
                                <div><br></br></div>
                                <Typography variant="h5" component="h2">
                                {translate('home.platform.feature4')}
                                </Typography>
                                <Typography color="textSecondary">
                                {translate('home.platform.feature4.description')}
                                </Typography>
                            </CardContent>
                            </Card>
                            <div><br></br></div>
                            <div><br></br></div>
                            <Typography variant="h2" component="h3">
                                <br></br>
                                {translate('home.consulting')}
                            </Typography>
                            <Typography variant="h5" component="h5">
                                {translate('home.consulting.intro1')}
                            </Typography>
                            <Typography variant="h5" component="h5">
                                {translate('home.consulting.intro2')}
                            </Typography>
                            <Typography variant="h5" component="h5">
                                {translate('home.consulting.intro3')}
                            </Typography>
                            <Typography variant="h5" component="h5">
                                {translate('home.consulting.intro4')}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card elevation={0}>
                        <CardContent>
                            <div><br></br></div>
                            <div><br></br></div>
                            <div><br></br></div>
                            <Paper elevation={0}>
                                <Typography>{translate('home.copyright')}</Typography>
                            </Paper>
                            <Button href="/terms-of-use.html">
                                <OpenInNew style={{ paddingRight: '0.5em' }} />
                                {translate('home.terms')}
                            </Button>
                            <Button href="/privacy-policy.html">
                                <OpenInNew style={{ paddingRight: '0.5em' }} />
                                {translate('home.privacy')}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
