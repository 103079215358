import React from 'react';
import './index.css';
import logo from '../../../images/logo.png';

const Header = () => (
  <div className="header">
    <div>
      <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
        <img aria-hidden="true" src={logo} />
      </a>
    </div>
  </div>
);

export default Header;
