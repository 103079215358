import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import jwt_decode from 'jwt-decode';

import logo from '../../../images/logo.png';
import RegistrationApi from './api';
import { Registration, Input } from '../types';
import './index.css';

const formTemplate = [
  {
    label: 'First Name',
    name: 'firstName',
    isRequired: false,
    error: '',
    value: ''
  },
  {
    label: 'Last Name',
    name: 'lastName',
    isRequired: false,
    error: '',
    value: ''
  },
  {
    label: 'Email',
    name: 'email',
    isRequired: true,
    error: '',
    value: ''
  }
];

async function putRegistration(registration: Registration) {
  return await new RegistrationApi().putUser(registration);
}

function moreThanOneHundredChars(text: string) {
  return text.length > 100;
}

function moreThanOneChar(text: string) {
  return text.length === 0;
}

const RegistrationPage = () => {

  const [error, setError] = useState('');
  const [isRegistering] = useState(false);
  const [isHandlingMicrosoftCallback] = useState(false);
  const navigate = useNavigate();

  const {assessment} = useParams();
  var assessmentName = assessment;
  const [formData, setFormData] = useState<Input[]>(
    formTemplate
  );

  const handleGoogleCredentialResponse = (response:any) => {
    const decoded:any = jwt_decode(response.credential);
    const registration:Registration = {firstName:decoded.given_name,lastName:decoded.family_name,email:decoded.email,assessmentName:assessmentName||''};
    submitRegistration(registration,assessmentName||'');
  }


  const { linkedInLogin } = useLinkedIn({
    clientId: '78krvlgt8rrhec',
    redirectUri: `${window.location.origin}/assessments`,
    onSuccess: (code) => {
      console.info(code);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleMicrosoftAuthCallback = useCallback(async (err:any, data:any, msal:any) => {

    if (err) {
      setError('Error');
      return;
    }
    if (!err && data) {
      const name = data.account.name.split(" ", 2);
      const registration:Registration = {firstName:name[0],lastName:name[1],email:data.account.userName,assessmentName:assessmentName||''};
      submitRegistration(registration,assessmentName||'');
      //msal.logout();
    }

  }, [isHandlingMicrosoftCallback]);


  const onGithubSuccess = (response:any) => {
    //const decoded:any = jwt_decode(response.credential);
    //const registration:Registration = {firstName:decoded.given_name,lastName:decoded.family_name,email:decoded.email,assessmentName:assessmentName||''};
    //submitRegistration(registration,assessmentName||'');
  }

  const onGithubFailure = () => {
    setError('Error');
  }

  const updateForm = (value: string, index: number) => {
    const freshForm = [...formData];
    freshForm[index].value = value;
    setFormData(freshForm);
  };

  async function validateForm(form: Input[]) {
    const freshForm = [...formData];
    await Promise.all(
      form.map(async (singleInput, i) => {
        if (moreThanOneHundredChars(singleInput.value)) {
          freshForm[i].error = 'Must be less than 100 characters.';
          throw Error('Must be less than 100 characters.');
        }
        if (singleInput.isRequired && moreThanOneChar(singleInput.value)) {
          freshForm[i].error = 'Required field.';
          throw Error('Required Field');
        }
        return true;
      })
    );

    return form.reduce(
      (responseObj: any, singleInput: Input) => {
        const name = singleInput.name;
        return Object.assign(responseObj, { [name]: singleInput.value });
      },
      {}
    );
  }

  const submitRegistration = async (registration:Registration,assessmentName:string) => {
    try {
      const response = await putRegistration({
        ...registration,
        assessmentName
      });

      if (response.status == 200) {
        localStorage.setItem('assessmentName', assessmentName || '');
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('status', 'pending');
        navigate(`/assessments`);
        ReactGA.event({
          category: 'Registration',
          action: 'Successful Registration'
        });
      }
    } catch (err) {
      console.error(err);
      setError('Error');
      ReactGA.event({
        category: 'Registration',
        action: 'Failed Registration'
      });
    }

  }

  const validateThenSubmitRegistration = useCallback(async () => {

    const registration = await validateForm(formData);
    submitRegistration(registration,assessmentName||'');

  }, [isRegistering]);

  return (
    <div className="registrationWrapper">
      <div className="header">
        <div>
          <img src={logo} />
        </div>
      </div>
      <div className="registrationBody">
        <div className="registrationContentWrapper">
          <div className="registrationContent">
            <div className="registrationForm">
              <div className='assessmentName'>{assessmentName} assessment</div>
              <br />
              <div className="registrationInputs">
                {formTemplate.map((singleInput, i) => (
                  <div
                    key={'singleInput-' + i}
                    className={`registrationSingleInput 
                    ${singleInput.error.length ? 'errored' : ''}
                    ${singleInput.value.length ? 'active' : ''}
                    `}
                  >
                    <label>{singleInput.label}</label>
                    <input
                      id={singleInput.name}
                      type="text"
                      onChange={(evt) => updateForm(evt.target.value, i)}
                      value={singleInput.value}
                    />
                    {singleInput.error.length > 0 && (
                      <div className="inputError">
                        <span>
                          {singleInput.error}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {error && (
                <div className="errors">
                  <span>{error}</span>
                </div>
              )}
              <div className="registrationButton">
                <button className="submit" onClick={() => validateThenSubmitRegistration()}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <br />
          <br />
          <div className="registrationFooter">
          <span>© techassessments.io 2024. All rights reserved. </span>
          <br />
          </div>
          <br />
          <a href={process.env.PUBLIC_URL + '/privacy-policy.html'}
            className="footerLeftLink"
            target="_blank">
            <span>Privacy</span>
          </a>

          <a href={process.env.PUBLIC_URL + '/terms-of-use.html'}
          className="footerRightLink"
          target="_blank">
            <span>Terms</span>
          </a>

        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
