import React, { useState, useEffect, forwardRef }from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logo from '../../images/logo.png';
import MaterialTable from 'material-table';
import positionCreate from '../../images/delivery-send.png';
import positionCancel from '../../images/delivery-cancel.png';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import PositionsApi from './api';
import { Position } from './position';
import './index.css';


// const tableIcons = {
//   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
// };

const state = {
  positions: []
};

function setState(state: any) {
  state = state;
}

const translate = (key: string) => {
  if (key === 'home.logout') {
      return 'Logout';
  }
}

export default function Positions() {

  const [page] = useState(1);
  const [positions, setPositions] = useState([]);
  const navigate = useNavigate();

  const submitLogout = async () => {
    try {
      localStorage.setItem('Id', '');
      navigate(`/`);
    } catch (err) {
      throw Error('Unable to submit logout');
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      const response = await new PositionsApi().getPositions();
      console.log(response.data);
      setPositions(response.data.Items);
    }

  fetchData()
  .catch(console.error);


    // .catch(error => {
    //   console.error(error);
    //   throw Error('Unable to get positions');
    // });
  }, [page]);


  return (
    <Container>
    <div className="positions">
    <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
      <img aria-hidden="true" src={logo} />
    </a>
    <Button onClick={() => submitLogout()}>
    {translate('home.logout')}
    </Button>
    </div>
    <MaterialTable
      title=""
      actions={[
        {
          icon: () => <img aria-hidden="true" src={positionCreate} />,
          tooltip: "Deliver",
          onClick: (event, rowData) => {
            const rowJson = JSON.stringify(rowData, null, 2);
            const answer = window.confirm(`Send For Delivery? : ${rowJson}`);
            if (answer) {
              //send for delivery
              console.log(answer)
            }
            else {
                //some code
                console.log('else')
            }
          },
        },
        {
          icon: () => <img aria-hidden="true" src={positionCancel} />,
          tooltip: "Cancel",
          onClick: (event, rowData) => {
            const rowJson = JSON.stringify(rowData, null, 2);
            const answer = window.confirm(`Cancel Delivery? : ${rowJson}`);
            if (answer) {
              //cancel for delivery
              console.log(answer)
            }
            else {
                //some code
                console.log('else')
            }
          },
        },
      ]}
      columns={[
        { title: 'Position Number', field: 'PositionNumber.S' },
        { title: 'Customer', field: 'PositionCustomer.S' },
        { title: 'Position Items', field: 'PositionLineItems.S' },
        ]}
      data={positions}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new PositionsApi().positions.put(newData)
              .then(res => res.json())
              .then(response => {
                console.log(response.data.Items)
                  setPositions(response.data.Items);
              })
              .catch(error => {
                throw Error('Unable to put positions');
              });
              setState((prevState: { data: any; }) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData:any, oldData:any) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new PositionsApi().positions.patch(newData)
              .then(res => res.json())
              .then(response => {
                  
                  setPositions(response.data.Items);
              })
              .catch(error => {
                throw Error('Unable to patch positions');
              });
              if (oldData) {
                setState((prevState: { data: any; }) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData:Position) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new PositionsApi().positions.delete(oldData)
              .then(res => res.json())
              .then(response => {
                  setPositions(response.data.positions);
              })
              .catch(error => {
                throw Error('Unable to delete positions');
              });
              setState((prevState: { data: any; }) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
    </Container>
  );
}