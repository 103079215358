import axios, { AxiosResponse } from 'axios';
import { QuestionAnalytic, Results } from './types';

const ASSESSMENTS = `https://axd2xvrbdjokvjdzao4g5epuuy0qsdjz.lambda-url.us-east-1.on.aws`;
const ASSESSMENTS_SUBMISSION = `https://nd4nqdglofiix7mjrwxzgaueeu0rzoeu.lambda-url.us-east-1.on.aws`;
const QUESTION_ANALYTICS = `https://whqmyjlufmltvluregf4jskgke0kelhu.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}

class AssessmentApi {

  getAssessment = async (name:String): Promise<AxiosResponse> => {
    try {
      if (localStorage.getItem('token')) {
      headers['authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
      return axios.post(`${ASSESSMENTS}`,{'Name': name}, { headers });
    } catch (e) {
      throw e;
    }
  }

  putAssessmentResults = async (results:Results): Promise<AxiosResponse> => {
    try {
      if (localStorage.getItem('token')) {
        headers['authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
      return axios.put(`${ASSESSMENTS_SUBMISSION}`,results, { headers });
    } catch (e) {
      throw e;
    }
  }

  putQuestionAnalytic = async (questionAnalytic:QuestionAnalytic): Promise<AxiosResponse> => {
    try {
      if (localStorage.getItem('token')) {
        headers['authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
      return axios.put(`${QUESTION_ANALYTICS}`,{'assessmentId': questionAnalytic.assessmentId,'questionId': questionAnalytic.question.id,'timeToAnswer': String(questionAnalytic.timeToAnswer),'numberOfClicks': String(questionAnalytic.numberOfClicks),'correct': String(questionAnalytic.correct)}, { headers });
    } catch (e) {
      throw e;
    }
  }  

}
export default AssessmentApi;