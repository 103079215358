import React, { useContext } from 'react';
import "./index.css";
import AssessmentContext from '../context'
import { Question } from '../types';

const ProgressBar: React.FC<{ sectionId: number }> = ({ sectionId }) => {
    const { assessment } = useContext(AssessmentContext);

    const sectionQuestions = assessment.sections[sectionId].questions;
    const sectionAnswers = sectionQuestions.filter((question: Question) => question.choosenQuestionOption != null).length

    const filledWidthPercent = Math.round((sectionAnswers / sectionQuestions.length) * 100);

    return (
        <div>
            <div className="emptyBar">
                <span className="fillerBar" style={{ width: `${filledWidthPercent}%` }} />
            </div>
            <div className="sectionQuestionsLabel">
                <div>{sectionQuestions.length} questions</div>
                <div>{filledWidthPercent}% completed</div>
            </div>
        </div>
    )
}

export default ProgressBar;